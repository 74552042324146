import {http, httpCrm} from "../../Utils/Conections/http-common-finbe";
import httpVin from "../../Utils/Conections/http-common-vin";
const getClients = (params) => {
  return http.get("/ClientesDispLinea", params);
};

const getLegalPersons = (params) => {
  return http.get("/RepresentantesLegales", params);
};

/** Get credit lines of customer  **/
const getCreditLines = (params) => {
  return http.get("/LineasCredito", params);
};

/** Get information credit lines  **/
const getInfoCreditLine = (params) => {
  return http.get("/InformacionLineaCredito", params);
};
const getConfigurationCreditLine = (params) => {
  return httpCrm.get("/ConfiguracionClientesDispLinea", params);
};
const getInventory = (params) => {
  return http.get("/InventarioCredito", params);
};
/**Get validate saldos */
const getDetailCredit = (params) => {
  return http.get("/DetalleCreditos", params);
};
/**Get Avales */
const getAvales = (params) => {
  return http.get("/Avales", params);
};

const validateTransunion = async (vin) => {
  try {
    const { data } = await httpVin.post("/vehicles/api/v1/trans-union", {
      niv: vin,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
const validateRepuve = async (vin) => {
  try {
    const { data } = await httpVin.post("/vehicles/api/v1/repuve", {
      niv: vin,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
const validateAX = async (vin) => {
  try {
    const { data } = await http.get("/InformacionVin", {
      params: { vin },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export default {
  getClients,
  getLegalPersons,
  getCreditLines,
  getInfoCreditLine,
  getInventory,
  getConfigurationCreditLine,
  getDetailCredit,
  getAvales,
  validateAX,
  validateRepuve,
  validateTransunion,
};
