import axios from "axios";
import dotenv from "dotenv";

dotenv.config();

const http = axios.create({
  baseURL: process.env.REACT_APP_CONNECTION_FINBE,
  headers: {
    "Content-type": "application/json",
    "Authorization": process.env.REACT_APP_TOKEN_FINBE,
  },
});

const httpCrm = axios.create({
  baseURL: process.env.REACT_APP_CONNECTION_CRM,
  headers: {
    "Content-type": "application/json",
    "Authorization": process.env.REACT_APP_TOKEN_FINBE,
  },
});

// // Interceptor para capturar las solicitudes
// http.interceptors.request.use(
//   (config) => {
//     console.log("Request:");
//     console.log("URL:", config.url);
//     console.log("Method:", config.method);
//     console.log("Headers:", config.headers);
//     console.log("Params:", config.params);
//     console.log("Data:", config.data);
//     return config;
//   },
//   (error) => {
//     console.error("Request Error:", error);
//     return Promise.reject(error);
//   }
// );

// // Interceptor para capturar las respuestas
// http.interceptors.response.use(
//   (response) => {
//     console.log("Response:");
//     console.log("Data:", response.data);
//     console.log("Status:", response.status);
//     console.log("Headers:", response.headers);
//     return response;
//   },
//   (error) => {
//     console.error("Response Error:", error);
//     if (error.response) {
//       console.error("Status:", error.response.status);
//       console.error("Data:", error.response.data);
//       console.error("Headers:", error.response.headers);
//     }
//     return Promise.reject(error);
//   }
// );

export {http, httpCrm};
